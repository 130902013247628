var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("cart-heading", {
        attrs: {
          title: "Select your preferred payment method",
          subtitle: "Pay securly with Credit Card or PayPal.",
        },
      }),
      _c("div", { staticClass: "grid grid__col2" }, [
        _c(
          "div",
          {
            staticClass: "paymentType",
            on: {
              click: function ($event) {
                return _vm.choosePaymentType(_vm.PaymentType.CreditCard)
              },
            },
          },
          [
            _c("icon", {
              staticClass: "paymentType__icon",
              attrs: { name: "credit-card" },
            }),
            _c("p", [_vm._v("Credit card")]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "paymentType",
            on: {
              click: function ($event) {
                return _vm.choosePaymentType(_vm.PaymentType.PayPal)
              },
            },
          },
          [
            _c("icon", {
              staticClass: "paymentType__icon",
              attrs: { name: "paypal" },
            }),
            _c("p", [_vm._v("PayPal")]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "cartNavigation" }, [
        _c(
          "a",
          {
            staticClass: "btn btn--lg btn--darkGrey btn--border",
            on: {
              click: function ($event) {
                return _vm.SET_CART_STEP(_vm.CartStep.Review)
              },
            },
          },
          [_vm._v("PREVIOUS")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }