

















import { Vue, Prop, Component, Mixins } from 'vue-property-decorator'
import { analyticsPageView, analyticsCheckoutInitiate } from '~/services/analytics'

import CartHeading from './CartHeading.vue'
import { CartStep, CartStore, PaymentType } from '~/store/cart'

@Component({ components: { CartHeading } })
export default class CartPaymentOptions extends Vue {
    PaymentType = PaymentType

    @CartStore.Getter('cartTotalAmount') cartTotalAmount: number

    @CartStore.Mutation('SET_CART_STEP') SET_CART_STEP: (cartStep: CartStep) => void
    @CartStore.Mutation('SET_PAYMENT_METHOD') SET_PAYMENT_METHOD: (paymentType: PaymentType) => void

    choosePaymentType(paymentType: PaymentType) {
        // save the payment type in da store
        this.SET_PAYMENT_METHOD(paymentType)
        // to the next step
        this.SET_CART_STEP(CartStep.PaymentForm)
    }
    created() {
        // send page view event to GA for payment option selection
        analyticsPageView({ page: 'cart/payment-options', title: 'Payment Options' })
        analyticsCheckoutInitiate(this.cartTotalAmount)
    }
}
